import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'


import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { API } from 'lib/rest';
import { ENDPOINTS } from 'config/network';

import { useTable, useGroupBy, useExpanded, useSortBy } from 'react-table'
const Styles = styled.div`
  table {
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tr:nth-child(even) {background-color: #f6f6f6;}
    tr.expandable:hover,div.openable:hover {
      background-color : #eff;
    }
    
    th,
    td {
      margin: 0;
      padding: 0.2rem;

      
    }
  }
`
const first = (leafValues) => {
  for (var i = 0; i < leafValues.length; i++) { if (typeof leafValues[i] !== 'undefined') return leafValues[i] }
}

const scoreHeader = () => <OverlayTrigger overlay={<Tooltip>Score</Tooltip>}>
  <span className="text-info" style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'star-half-alt']} /></span>
</OverlayTrigger>
const progressionHeader = () => <OverlayTrigger overlay={<Tooltip>Evolution</Tooltip>}>
  <span className="text-info" style={{ cursor: "help" }}><FontAwesomeIcon icon={['fas', 'chart-line']} /></span>
</OverlayTrigger>

const scoreCell = ({ cell }) => {
  var details
  try { details = cell.row.original[cell.column.id.split('.')[0]] } catch (e) { }
  return (cell.value) ? Details(details, cell.column.id.split('.')[0], cell.value) : ""
}

const scoreAggregated = ({ value }) =>
  (value) ? <div className={value > 0.9 ? 'bg-success' : value > 0.5 ? 'bg-warning' : 'bg-danger'} style={{ textAlign: 'center', width: '100%', height: '100%', display: 'inline-block' }}>&nbsp;
  </div> : ""


const progressionCell = ({ value }) => {
  if (value === 0) return <OverlayTrigger overlay={<Tooltip>+ {value} %</Tooltip>}>
    <FontAwesomeIcon className="text-warning" style={{ cursor: "help" }} icon={['fas', 'equals']} />
  </OverlayTrigger>

  if (value >= 0.25) return <OverlayTrigger overlay={<Tooltip>+{parseInt(value * 100)} %</Tooltip>}>
    <FontAwesomeIcon className="text-success" style={{ cursor: "help" }} icon={['fas', 'angle-double-up']} />
  </OverlayTrigger>

  if (value > 0) return <OverlayTrigger overlay={<Tooltip>+{parseInt(value * 100)} %</Tooltip>}>
    <FontAwesomeIcon className="text-success" style={{ cursor: "help" }} icon={['fas', 'angle-up']} />
  </OverlayTrigger>

  if (value <= 0.25) return <OverlayTrigger overlay={<Tooltip>{parseInt(value * 100)} %</Tooltip>}>
    <FontAwesomeIcon className="text-danger" style={{ cursor: "help" }} icon={['fas', 'angle-double-down']} />
  </OverlayTrigger>

  if (value < 0) return <OverlayTrigger overlay={<Tooltip>{parseInt(value * 100)} %</Tooltip>}>
    <FontAwesomeIcon className="text-danger" style={{ cursor: "help" }} icon={['fas', 'angle-down']} />
  </OverlayTrigger>

  return String("")
}

const progressionAggregated = ({ value }) => {
  if (value === 0) return <FontAwesomeIcon className="text-warning" icon={['fas', 'equals']} />
  if (value >= 0.25) return <FontAwesomeIcon className="text-success" icon={['fas', 'angle-double-up']} />
  if (value > 0) return <FontAwesomeIcon className="text-success" icon={['fas', 'angle-up']} />
  if (value <= -0.25) return <FontAwesomeIcon className="text-danger" icon={['fas', 'angle-double-down']} />
  if (value < 0) return <FontAwesomeIcon className="text-danger" icon={['fas', 'angle-down']} />
  return String("")
}

function Details(details, phoneme, value) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var title;
  switch (phoneme) {
    case 'phoneme_1': { title = 'é'; break }
    case 'phoneme_2': { title = '(f|F)'; break }
    case 'phoneme_3': { title = '(i|î)'; break }
    case 'phoneme_4': { title = '(j|J)'; break }
    case 'phoneme_5': { title = '(l|L)'; break }
    case 'phoneme_6': { title = '(m|M)'; break }
    case 'phoneme_7': { title = '(o|ô)'; break }
    case 'phoneme_8': { title = '(r|R)'; break }
    case 'phoneme_9': { title = '(u|û)'; break }
    case 'phoneme_10': { title = '(b|B)'; break }
    case 'phoneme_11': { title = '(e|E)'; break }
    case 'phoneme_12': { title = '(n|N)'; break }
    case 'phoneme_13': { title = 'on'; break }
    case 'phoneme_14': { title = 'ou'; break }
    case 'phoneme_15': { title = '(p|P)'; break }
    case 'phoneme_16': { title = '(t|T)'; break }
    case 'phoneme_17': { title = '(v|V)'; break }
    case 'phoneme_18': { title = '(an|en)'; break }
    case 'phoneme_19': { title = '(au|eau)'; break }
    case 'phoneme_20': { title = '(c|qu)'; break }
    case 'phoneme_21': { title = '(ch|Ch)'; break }
    case 'phoneme_22': { title = '(d|D)'; break }
    case 'phoneme_23': { title = '(oi|oî)'; break }
    case 'phoneme_24': { title = '(s|z)'; break }
    case 'phoneme_25': { title = 'ain'; break }
    case 'phoneme_26': { title = '(è|ê|ai[^nm]?|err?|ess)'; break }
    case 'phoneme_27': { title = '(er|ez|et)'; break }
    case 'phoneme_28': { title = '(eu|œu)'; break }
    case 'phoneme_29': { title = '(g|gu)'; break }
    case 'phoneme_30': { title = '(ge|gi|gé|gè|gê|gî)'; break }
    case 'phoneme_31': { title = 'in'; break }
    case 'phoneme_32': { title = '(s|S|ss|ce|ci)'; break }
    case 'phoneme_33': { title = '(un|um|ein|aim|ym)'; break }
    case 'phoneme_34': { title = 'gn'; break }
    case 'phoneme_35': { title = '(ill|ie|hi|ay|io|ail|y)'; break }
    case 'phoneme_36': { title = 'oin'; break }
    case 'phoneme_37': { title = '(a|â|A)'; break }
    default: { title = 'Erreur' }
  }
  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Cliquez pour ouvrir le détail</Tooltip>}>
        <div className={value > 0.9 ? 'bg-success' : value > 0.5 ? 'bg-warning' : 'bg-danger'} style={{ textAlign: 'center', width: '100%', height: '100%', display: 'inline-block', cursor: 'pointer' }} onClick={handleShow}>&nbsp;</div>
      </OverlayTrigger>

      <Modal show={show} onHide={handleClose} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md='12' lg='4'>
              {details.exercise_1 &&
                <>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}><img alt='Activité 1' width='24px' src={require('assets/icons/Asset_1.png')} /> Discrimination</div>
                  {details.exercise_1.answers && details.exercise_1.answers.map((answer, i) =>
                    <div style={{ textAlign: 'center' }} key={i}>
                      {answer.options && <>{(i + 1)}.&nbsp;&nbsp;
                      <span style={{ marginRight: '1px', marginLeft: '1px', paddingLeft: '1px', paddingRight: '1px', border: '1px solid #bbb', borderRadius: '5px', backgroundColor: '#cfc' }}>{answer.options.trueword} {answer.options.trueword === answer.answered ? <FontAwesomeIcon className="text-success" icon={['fas', 'check']} /> : ''}</span>
                        <span style={{ marginRight: '1px', marginLeft: '1px', paddingLeft: '1px', paddingRight: '1px', border: '1px solid #bbb', borderRadius: '5px', backgroundColor: answer.options.falseword === answer.answered ? '#fcc' : '' }}>{answer.options.falseword} {answer.options.falseword === answer.answered ? <FontAwesomeIcon className="text-danger" icon={['fas', 'times']} /> : ''}</span>
                      </>
                      }

                      {!answer.options && <span style={{ paddingLeft: '1px', paddingRight: '1px', borderBottom: '1px #ddd solid' }}>{(i + 1)}. {answer.answered} {(answer.answered === answer.expected) ? <FontAwesomeIcon className="text-success" icon={['fas', 'check']} /> : <span><FontAwesomeIcon className="text-danger" icon={['fas', 'times']} /> (réponse attendue : {answer.expected})</span>}</span>}
                    </div>
                  )}
                </>
              }
            </Col>
            <Col md='12' lg='4'>
              {details.exercise_2 &&
                <>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}><img alt='Activité 2' width='24px' src={require('assets/icons/Asset_2.png')} /> Suppression syllabique</div>
                  {details.exercise_2.answers && details.exercise_2.answers.map((answer, i) => {
                    var answered = JSON.parse(answer.answered), expected = JSON.parse(answer.expected)
                    return <div style={{ textAlign: 'center' }} key={i}>
                      {(i + 1)}.&nbsp;&nbsp;{answer.fullWord.split("").map((char, j) =>
                        <div key={j} style={{ display: 'inline-block' }}> <span style={{ marginRight: '1px', marginLeft: '1px', paddingLeft: '1px', paddingRight: '1px', border: '1px solid #bbb', borderRadius: '5px', backgroundColor: (expected.includes(j) ? '#cfc' : answered.includes(j) ? '#fcc' : '#fff') }} key={j}>{char} </span><br /> {expected.includes(j) && answered.includes(j) ? <FontAwesomeIcon className="text-success" icon={['fas', 'check']} /> : answered.includes(j) ? <FontAwesomeIcon className="text-danger" icon={['fas', 'times']} /> : <FontAwesomeIcon style={{ color: '#fff' }} icon={['fas', 'check']} />}</div>
                      )}
                    </div>
                  })}
                </>
              }
            </Col>


            <Col md='12' lg='4'>
              {details.exercise_3 &&
                <>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}><img alt='Activité 3' width='24px' src={require('assets/icons/Asset_3.png')} /> Segmentation syllabique</div>
                  {details.exercise_3.answers && details.exercise_3.answers.map((answer, i) =>
                    <div style={{ textAlign: 'center' }} key={i}>
                      {(i + 1)}.&nbsp;&nbsp;{answer.syllabes.map((syl, j) =>
                        <div style={{ display: 'inline-block', marginRight: '1px', marginLeft: '1px', paddingLeft: '1px', paddingRight: '1px', border: '1px solid #bbb', borderRadius: '5px', backgroundColor: answer.isGood ? '#cfc' : '#fcc' }} key={j}>{syl.map((index) => answer.fullWord[index])}</div>
                      )}
                    </div>
                  )}
                </>
              }
            </Col>


            <Col md='12' lg='4'>
              {details.exercise_4 &&
                <>
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}><img alt='Activité 4' width='24px' src={require('assets/icons/Asset_4.png')} /> Encodage</div>
                {details.exercise_4.answers && details.exercise_4.answers.map((answer, i) => <div style={{ textAlign: 'center' }} key={i}>
                      {(i + 1)}.&nbsp;&nbsp;{answer.expected.split("").map((char, j) =>
                        <div key={j} style={{ display: 'inline-block' }}> <span style={{ marginRight: '1px', marginLeft: '1px', paddingLeft: '1px', paddingRight: '1px', border: '1px solid #bbb', borderRadius: '5px', backgroundColor: (answer.answered[j] === char ? '#cfc' : '#fcc') }} key={j}>{answer.answered[j]} </span><br /> {answer.answered[j] === char ? <FontAwesomeIcon className="text-success" icon={['fas', 'check']} /> : <FontAwesomeIcon className="text-danger" icon={['fas', 'times']} />}</div>
                      )}
                  {answer.expected !== answer.answered && <span><br />(réponse attendue : {answer.expected})</span>}
                </div>
                  )}
                </>
              }
            </Col>
            {!details && <h1>Données introuvables</h1>}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data,
    initialState: {
      groupBy: ['name'],
      sortBy: [{ id: 'name' }, { id: 'date', desc: true }]
    }
  },
    useGroupBy,
    useSortBy,
    useExpanded)

  // Render the UI for your table
  return (
    <table style={{ maxHeight: '600px', minWidth: '1000px', width: "100%" }}{...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th style={{ textAlign: 'center' }} {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} {...(row.isGrouped ? row.getToggleRowExpandedProps({ title: "Cliquez pour  " + (row.isExpanded ? "cacher" : "afficher") + " toutes les exercices de cet élève", className: 'expandable' }) : undefined)}>
              {row.cells.map(cell => {
                return <td style={{ "whiteSpace": "nowrap" }} {...cell.getCellProps()} >{cell.isGrouped ? (
                  // If it's a grouped cell, add an expander and row count
                  <>
                    <span>
                      {row.isExpanded ? <FontAwesomeIcon className="text-info" icon={['fas', 'minus-square']} /> : <FontAwesomeIcon className="text-info" icon={['fas', 'plus-square']} />}
                    </span>{' '}
                    {cell.render('Cell')} ({row.subRows.length})
                  </>
                ) : cell.isAggregated ? (
                  // If the cell is aggregated, use the Aggregated
                  // renderer for cell
                  cell.render('Aggregated')
                ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                  // Otherwise, just render the regular cell
                  cell.render('Cell')
                )}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}


export default class Exercices extends Component {

  state = {
    loading: true,
    detailsLoading: false,
    students: [],
    details: null,
  };

  componentDidMount() {
    this.getData();
  }


  getData = () => {
    if (this.props.account.type === 'PRIVATE') {
      this.props.history.push('/home');
      return;
    } else {
      API.get(ENDPOINTS.studentsExercises, this.props.token).then(result => {
        if (result.students instanceof Array) {
          this.setState({
            loading: false,
            students: result.students
          });
        }
      })
    }
  };

  getColumn = (title, id) => {
    return {
      Header: title,
      columns: [
        {
          Header: scoreHeader,
          accessor: 'phoneme_' + id + '.score',
          aggregate: first,
          Cell: scoreCell,
          Aggregated: scoreAggregated
        },
        {
          Header: progressionHeader,
          accessor: 'phoneme_' + id + '.progression',
          aggregate: first,
          Cell: progressionCell,
          Aggregated: progressionAggregated
        }
      ]
    }
  }


  render() {
    const columns = [
      {
        Header: 'Éleve',
        accessor: 'name',
      },
      {
        Header: 'Dernier exercice',
        accessor: 'date',
        aggregate: first,
        Aggregated: ({ value }) => value ? moment(value).fromNow() : "Aucune",
        Cell: ({ value }) => value ? moment(value).format('L LT') : "Aucune"
      },
      this.getColumn('A', 37),
      this.getColumn('I', 3),
      this.getColumn('R', 8),
      this.getColumn('L', 5),
      this.getColumn('U', 9),
      this.getColumn('O', 7),
      this.getColumn('M', 6),
      this.getColumn('É', 1),
      this.getColumn('F', 2),
      this.getColumn('J', 4),
      this.getColumn('E', 11),
      this.getColumn('N', 12),
      this.getColumn('OU', 14),
      this.getColumn('P', 15),
      this.getColumn('V', 17),
      this.getColumn('ON', 13),
      this.getColumn('B', 10),
      this.getColumn('T', 16),
      this.getColumn('C|QU', 20),
      this.getColumn('D', 22),
      this.getColumn('CH', 21),
      this.getColumn('OI', 23),
      this.getColumn('AU', 19),
      this.getColumn('AN', 18),
      this.getColumn('S|Z', 24),
      this.getColumn('È|Ê|AI', 26),
      this.getColumn('IN', 31),
      this.getColumn('AIN', 25),
      this.getColumn('UN', 33),
      this.getColumn('SS|CE', 32),
      this.getColumn('G|GU', 29),
      this.getColumn('EU|ŒU', 28),
      this.getColumn('ER|EZ', 27),
      this.getColumn('GE||GI', 30),
      this.getColumn('OIN', 36),
      this.getColumn('GN', 34),
      this.getColumn('ILL|Y', 35),
    ]

    return (
      <Row className="homePadding">
        <Col sm>
          <Card>
            <Card.Header>
              Exercices des élèves
            </Card.Header>
            <Card.Body>
              {this.state.loading && <h6>Chargement...</h6>}
              {!this.state.loading && <Styles style={{ maxHeight: '600px', overflow: 'auto' }}><Table columns={columns} data={this.state.students} evalState={this.state} /></Styles>}
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    );
  }
}

Exercices.propTypes = {
  token: PropTypes.string,
  history: PropTypes.object,
};
